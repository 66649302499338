import React, { useEffect } from 'react';

export const Confirmlogin: React.FC = () => {
  useEffect(() => {
    const cook = process.env.REACT_APP_COOKIE_NAME;
    const urlParams = new URL(window.location.href).searchParams;
    const referrer = urlParams.get('referrer')?.toString() || '';
    const token = urlParams.get('token'); // Отримуємо значення "токен" з гет параметра
    let tokenReturn = token || 'none';
    if (token) {
      const expiresDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 180).toUTCString();
      document.cookie = `${cook}=${token}; domain=.expertus.com.ua; path=/; expires=${expiresDate}; secure=true; sameSite=none`;
    }


    if (referrer) {
      const cookies = document.cookie.split('; ').reduce((result, c) => {
        const [key, val] = c.split('=').map(decodeURIComponent);
              //@ts-ignore

        result[key] = val;
        return result;
      }, {});
      //@ts-ignore
      tokenReturn = cookies[cook] || 'none';

    }
 
      // Check if the URL already contains a 'tokenCoock' parameter
      const url = new URL(referrer);
      if(tokenReturn){
        if (url.searchParams.has('tokenCoock')) {
          url.searchParams.set('tokenCoock', tokenReturn);
        } else {
          url.searchParams.append('tokenCoock', tokenReturn);
        }
      }
   
      window.location.replace(url.toString());

  }, []);

  // Return null to indicate that there is no HTML to render
  return null;
};

