import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import {
  ActivateEmail,
  ChangePasswd,
  CheckAccess,
  CheckEmail,
  CheckToken,
  GetUserProfile,
  LoginUser,
  GetFBUser,
  upSocialDataAction,
  GetGoogleUser,
  Logout,
  RegistrationUser,
  ReqChangePasswd,
  TypeActivateEmailR,
  TypeChangePasswdR,
  TypeCheckAccessR,
  TypeCheckEmailR,
  TypeCheckTokenR,
  TypeGetUserProfileR,
  TypeLoginUserR,
  TypeGetGoogleUserR,
  TypeLogoutR,
  TypeRegistrationUserR,
  TypeReqChangePasswdR,
  TypeUpdateUserProfileR,
  UpdateUserProfile,
  TypeGetFBUserR,
  TypeCheckUserProfileR,
  CheckUserProfile
} from './actions';
import { ActionTypes, IUserProfile, TUser } from './types';
import { TOKEN } from '../../config';
const isMobileDevice = /iPhone|iPad|iPod|Android|Windows Phone/i.test(navigator.userAgent);
const isFacebookOrMobile = /FBAN|FBAV|Mobile/.test(navigator.userAgent);

function* loginUserWorker(action: ReturnType<typeof LoginUser.request>): Generator {
  const { data, callBack } = action.payload as TypeLoginUserR;

  let success = true;
  let error = '';

  try {
    let path = '/auth/login';
    const redirect = window.location.search;
    if (redirect) {
      path += redirect;
    }
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path,
    })) as TUser;
    if (resp.token) {
      localStorage.setItem(TOKEN.access, resp.token);
      const isAuthRedirect = isMobileDevice;
      const urlParams = new URL(window.location.href).searchParams
      const redirect2 = urlParams.get('redirectUrl');

      // window.addEventListener('message', function (event) {
      //   console.log('Received data:', event.data);
      //   console.log('event.origin', event.origin);
      //   if (event.origin !== 'https://devschool.buhplatforma.com.ua') {
      //     return;
      //   }
      //
      //   if (event.data?.authenticated) {
      //     // @ts-ignore
      //     localStorage.setItem('authenticated', true);
      //     localStorage.setItem('testToken', resp.tokenCoock);
      //   }
      // });
      if (redirect && redirect.includes('redirectUrl')) {
        if (isAuthRedirect) {
          if (resp.tokenCoock) {
            const expiresDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 180).toUTCString();
            document.cookie = `${process.env.REACT_APP_COOKIE_NAME}=${resp.tokenCoock}; domain=.expertus.com.ua; path=/; expires=${expiresDate}; secure=true; sameSite=none`;
          }
          if (redirect2) {
            if (isFacebookOrMobile) {
              const newRedirect = new URL(redirect2);
              if (newRedirect.searchParams.has('tokenCoock')) {
                newRedirect.searchParams.set('tokenCoock', resp.tokenCoock);
              } else {
                newRedirect.searchParams.append('tokenCoock', resp.tokenCoock);
              }
              window.location.href = newRedirect.href;

            } else {
              window.open(redirect2, '_parent');
              if (redirect && redirect.includes('isclose')) {
                window.close();
              }
            }
          }
        }else{
          if (redirect2) {
            window.open(redirect2, '_parent');
            if (redirect && redirect.includes('isclose')) {
              window.close();
              }
          }
        }

    
      } else {
        yield put(LoginUser.success(resp));
      }
      if (callBack) yield call(callBack, success, resp);

    } else {
      success = false;
      yield put(GetFBUser.success(resp));
      if (callBack) yield call(callBack, success, resp);
    }

  } catch (e) {
    success = false;
    error = e as string;
    yield put(LoginUser.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}
function* GetFBUserWorker(action: ReturnType<typeof GetFBUser.request>): Generator {
  const { data, callBack } = action.payload as TypeGetFBUserR;

  let success = true;
  let error = '';
  try {
    let path = '/auth/fbLogin';
    const redirect = window.location.search;
    if (redirect) {
      path += redirect;
    }
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path,
    })) as TUser;

    if (resp.token) {
      localStorage.setItem(TOKEN.access, resp.token);
      const isAuthRedirect = isMobileDevice;
      const urlParams = new URL(window.location.href).searchParams
      const redirect2 = urlParams.get('redirectUrl');
      if (redirect && redirect.includes('redirectUrl')) {
        if (isAuthRedirect) {
          if (resp.tokenCoock) {
            const expiresDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 180).toUTCString();
            document.cookie = `${process.env.REACT_APP_COOKIE_NAME}=${resp.tokenCoock}; domain=.expertus.com.ua; path=/; expires=${expiresDate}; secure=true; sameSite=none`;
          }
          if (redirect2) {
            if (isFacebookOrMobile) {
              const newRedirect = new URL(redirect2);
              if (newRedirect.searchParams.has('tokenCoock')) {
                newRedirect.searchParams.set('tokenCoock', resp.tokenCoock);
              } else {
                newRedirect.searchParams.append('tokenCoock', resp.tokenCoock);
              }
              window.location.href = newRedirect.href;

            }
          }
        }else{
          if (redirect2) {
            window.open(redirect2, '_parent');
            if (redirect && redirect.includes('isclose')) {
              window.close();
              }
          }
        }

    
      } else {
        yield put(LoginUser.success(resp));
      }
      if (callBack) yield call(callBack, success, resp);

    } else {
      success = false;
      yield put(GetFBUser.success(resp));
      if (callBack) yield call(callBack, success, resp);
    }

  } catch (e) {
    success = false;
    error = e as string;
    yield put(LoginUser.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}
function* getGoogleUserWorker(action: ReturnType<typeof GetGoogleUser.request>): Generator {
  const { data, callBack } = action.payload as TypeGetGoogleUserR;
  let success = true;
  let error = '';

  try {
    let path = '/auth/googleLogin';
    const redirect = window.location.search;
    if (redirect) {
      path += redirect;
    }
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path,
    })) as TUser;

    if (resp.token) {
      localStorage.setItem(TOKEN.access, resp.token);
      const isAuthRedirect = isMobileDevice;
      const urlParams = new URL(window.location.href).searchParams
      const redirect2 = urlParams.get('redirectUrl');

      if (redirect && redirect.includes('redirectUrl')) {
        if (isAuthRedirect) {
          if (resp.tokenCoock) {
            const expiresDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 180).toUTCString();
            document.cookie = `${process.env.REACT_APP_COOKIE_NAME}=${resp.tokenCoock}; domain=.expertus.com.ua; path=/; expires=${expiresDate}; secure=true; sameSite=none`;
          }
          if (redirect2) {
            if (isFacebookOrMobile) {
              const newRedirect = new URL(redirect2);
              if (newRedirect.searchParams.has('tokenCoock')) {
                newRedirect.searchParams.set('tokenCoock', resp.tokenCoock);
              } else {
                newRedirect.searchParams.append('tokenCoock', resp.tokenCoock);
              }
              window.location.href = newRedirect.href;

            }
          }
        }else{
          if (redirect2) {
            window.open(redirect2, '_parent');
            if (redirect && redirect.includes('isclose')) {
              window.close();
              }
          }
        }

    
      } else {
        yield put(LoginUser.success(resp));
      }
      if (callBack) yield call(callBack, success, resp);

    } else {
      success = false;
      yield put(GetFBUser.success(resp));
      if (callBack) yield call(callBack, success, resp);
    }

  } catch (e) {
    success = false;
    error = e as string;
    yield put(LoginUser.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* upSocialDataWorker(action: ReturnType<typeof upSocialDataAction.request>): Generator {
  const { data, callBack } = action.payload as TypeGetFBUserR;

  let success = true;
  let error = '';
  try {
    let path = '/auth/upSocialData';

    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path,
    })) as { succ?: string, error?: string };
    yield put(upSocialDataAction.success(resp));
    if (callBack) yield call(callBack, !!resp.succ, resp);

  } catch (e) {
    success = false;
    error = e as string;
    yield put(LoginUser.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}
function createPathFromSearchParams(): string {
  const searchParams = new URLSearchParams(window.location.search);
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'fbclid'];

  let path = '/auth/register';
  let queryParams: string[] = [];
  let redirectUrl: URL | undefined;
  let redirectParams: URLSearchParams | undefined;

  const entriesArray = Array.from(searchParams.entries());
  entriesArray.forEach(([key, value]) => {
    if (key === 'redirectUrl') {
      redirectUrl = new URL(decodeURIComponent(value));
      redirectParams = new URLSearchParams(redirectUrl.search);
    } else if (['app', 'email', 'sig', 'is_demo', 'product', 'pixelId'].includes(key)) {
      queryParams.push(`${key}=${value}`);
    }

    if (utmParams.includes(key) && redirectParams) {
      redirectParams.set(key, value);
    }
  });

  if (redirectUrl && redirectParams) {
    redirectUrl.search = redirectParams.toString();
    queryParams.push(`redirectUrl=${encodeURIComponent(redirectUrl.toString())}`);
  }

  if (queryParams.length > 0) {
    path += '?' + queryParams.join('&');
  }

  return path;
}


function* registrationUserWorker(action: ReturnType<typeof RegistrationUser.request>): Generator {
  const { data, callBack } = action.payload as TypeRegistrationUserR;

  let success = true;
  let error = {};

  const path: string = createPathFromSearchParams();

  try {
    const resp = yield call(callApi, {
      method: 'post',
      data,
      path,
    });

    yield put(RegistrationUser.success({}));

    if (callBack) {
      yield call(callBack, success, resp);
    }
  } catch (e) {
    success = false;
    error = e as any;
    yield put(RegistrationUser.error(e as string));
  } finally {
    if (callBack) {
      yield call(callBack, success, error);
    }
  }
}


function* logoutWorker(action: ReturnType<typeof Logout.request>): Generator {
  const { callBack } = action.payload as TypeLogoutR;

  let success = true;
  try {
    yield call(callApi, {
      method: 'get',
      path: `/auth/logout?originURL=${window.location.origin}`,
    });
    localStorage.clear();
  } catch (e) {
    success = false;
    yield put(Logout.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* checkAccessWorker(action: ReturnType<typeof CheckAccess.request>): Generator {
  const { callBack } = action.payload as TypeCheckAccessR;

  let success = true;
  try {
    const urlParams = new URL(window.location.href).searchParams
    const tokenCoock = urlParams.get('tokenCoock');
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/auth/check-login?appId=2500${tokenCoock ? '&tokenCoock=' + tokenCoock : ''}`,
    })) as TUser;
    if (resp.token) {
      const isAuthRedirect = isMobileDevice;
      if (isAuthRedirect && tokenCoock) {
        const expiresDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 180).toUTCString();
        document.cookie = `${process.env.REACT_APP_COOKIE_NAME}=${tokenCoock}; domain=.expertus.com.ua; path=/; expires=${expiresDate}; secure=true; sameSite=none`;
      }
    }
    yield put(CheckAccess.success(resp));
    localStorage.setItem(TOKEN.access, resp.token);
  } catch (e) {
    success = false;
    yield put(CheckAccess.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* activateEmailWorker(action: ReturnType<typeof ActivateEmail.request>): Generator {
  const { email, letterId, callBack } = action.payload as TypeActivateEmailR;
  const path = letterId ? `/auth/activate-email/${email}?&letterId=${letterId}` : `/auth/activate-email/${email}`;
  let success = true;
  try {
    yield call(callApi, {
      method: 'get',
      path,
    });
    yield put(ActivateEmail.success(''));
  } catch (e) {
    success = false;
    yield put(ActivateEmail.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* reqChangePasswdWorker(action: ReturnType<typeof ReqChangePasswd.request>): Generator {
  const { data, callBack } = action.payload as TypeReqChangePasswdR;
  let success = true;
  let error: any = {};
  try {
    yield call(callApi, {
      method: 'post',
      path: `/auth/change-passwd/req`,
      data,
    });
  } catch (e) {
    success = false;
    error = e;
    yield put(ReqChangePasswd.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* changePasswdWorker(action: ReturnType<typeof ChangePasswd.request>): Generator {
  const { data, callBack } = action.payload as TypeChangePasswdR;
  let success = true;
  let error = '';
  let resp = {};
  try {
    resp = (yield call(callApi, {
      method: 'post',
      path: `/auth/change-passwd`,
      data,
    })) as { email: string; name: string };
    yield put(ChangePasswd.success());
  } catch (e) {
    success = false;
    error = e as string;
    yield put(ChangePasswd.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error || resp);
  }
}

function* checkTokenWorker(action: ReturnType<typeof CheckToken.request>): Generator {
  const { data, callBack } = action.payload as TypeCheckTokenR;
  let success = true;
  let error = '';
  try {
    yield call(callApi, {
      method: 'post',
      path: `/auth/check-token`,
      data,
    });
  } catch (e) {
    success = false;
    error = e as string;
    yield put(CheckToken.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* getUserProfileWorker(action: ReturnType<typeof GetUserProfile.request>): Generator {
  const { callBack } = action.payload as TypeGetUserProfileR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/users/user`,
    })) as IUserProfile;
    yield put(GetUserProfile.success(resp));
  } catch (e) {
    success = false;
    yield put(GetUserProfile.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateUserProfileWorker(action: ReturnType<typeof UpdateUserProfile.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdateUserProfileR;
  let success = true;
  let error = {};
  let dataMessage = {};
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      path: `/users/profile`,
      data,
    })) as IUserProfile;
    dataMessage = resp as any;
    yield put(UpdateUserProfile.success(resp));
  } catch (e) {
    success = false;
    error = e as any;
    yield put(UpdateUserProfile.error(e as string));
  } finally {
    let respData = {};
    if (error && Object.keys(error).length > 0) {
      respData = error;
    }
    if (dataMessage && Object.keys(dataMessage).length > 0) {
      respData = dataMessage;
    }
    console.log(respData);
    if (callBack) yield call(callBack, success, respData);
  }
}

function* checkEmailWorker(action: ReturnType<typeof CheckEmail.request>): Generator {
  const { email, callBack } = action.payload as TypeCheckEmailR;
  let success = true;
  let result = false;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/auth/check-email/${email}`,
    })) as { exist: number };
    result = resp.exist ? false : true;
  } catch (e) {
    success = false;
    yield put(CheckEmail.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, result);
  }
}

function* checkUserProfileWorker(action: ReturnType<typeof CheckUserProfile.request>): Generator {
  const { data, callBack } = action.payload as TypeCheckUserProfileR;
  let success = true;
  let result = {};
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      path: `/users/check-profile`,
      data,
    })) as IUserProfile;
    result = resp;
  } catch (e) {
    success = false;
    yield put(CheckUserProfile.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, result);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.LOGOUT_USER_R, logoutWorker);
  yield takeEvery(ActionTypes.LOGIN_USER_R, loginUserWorker);
  yield takeEvery(ActionTypes.GOOGLE_USER_R, getGoogleUserWorker);
  yield takeEvery(ActionTypes.FB_USER_R, GetFBUserWorker);
  yield takeEvery(ActionTypes.SOCIAL_DATA_R, upSocialDataWorker);
  yield takeEvery(ActionTypes.REGISTRATION_USER_R, registrationUserWorker);
  yield takeEvery(ActionTypes.ACTIVATE_EMAIL_R, activateEmailWorker);
  yield takeEvery(ActionTypes.CHECK_ACCESS_R, checkAccessWorker);
  yield takeEvery(ActionTypes.REQ_CHANGE_PASSWD_R, reqChangePasswdWorker);
  yield takeEvery(ActionTypes.CHANGE_PASSWD_R, changePasswdWorker);
  yield takeEvery(ActionTypes.CHECK_TOKEN_R, checkTokenWorker);
  yield takeEvery(ActionTypes.GET_USER_PROFILE_R, getUserProfileWorker);
  yield takeEvery(ActionTypes.UPDATE_USER_PROFILE_R, updateUserProfileWorker);
  yield takeEvery(ActionTypes.CHECK_EMAIL_R, checkEmailWorker);
  yield takeEvery(ActionTypes.CHECK_USER_PROFILE_R, checkUserProfileWorker);
}

export default function* userSaga() {
  yield all([fork(watchFetchRequest)]);
}
